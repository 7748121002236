import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'

//�б�
export const listInfo = (page, pageSize, contractNo, partnerCorpId, contractTypeName, beginDate, endDate, productName, brand, code, id, status) => {
    return request({
        url: pvhApiBase + "Tracing/GetContracts",
        method: "get",
        params: {
            "contractNo": contractNo,
            "partnerCorpId": partnerCorpId,
            "contractTypeName": contractTypeName,
            "beginDate": beginDate,
            "endDate": endDate,
            "productName": productName,
            "brand": brand,
            "code": code,
            "id": id,
            "status": status,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//���ݺ�ͬid��ȡ�ɹ���Ϣ
export const POListById = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderListByContractId",
        method: "get",
        params: {
            "id": id
        }
    })
}

//����Batch��ƥ������
export const ContractList = (Contract) => {
    return request({
        url: pvhApiBase + "Tracing/GetContractNoList",
        method: "get",
        params: {
            "Contract": Contract
        }
    })
}

//����
export const addHead = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostContract",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editHead = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutContract",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��
export const delHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteContract",
        method: "delete",
        params: {
            id
        }
    })
}
//�ָ�
export const recoverHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/RecoverContract",
        method: "delete",
        params: {
            id
        }
    })
}

//����
export const addBody = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostContractDetail",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editBody = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutContractDetail",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��
export const delBody = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteContractDetail",
        method: "delete",
        params: {
            id
        }
    })
}


